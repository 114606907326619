@font-face {
  font-family: 'San Francisco';
  src: url('/public/sf-pro-display-bold.woff2') format('woff2');
}

.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('/public/TalkTuahBG.png') no-repeat center center/cover;
}
.message {
  position: absolute; /* Makes bubbles independent */
  margin-bottom: 20px;
}

.message.user {
  left: 55%;  /* Adjusts the user bubble position */
  top: 57%;
  background-color: #007aff;
  border-radius: 1000px;  /* More rounded */
  padding: 20px;        /* Larger padding */
  font-size: 16px;      /* Larger text */
  border: 2px solid black;  /* Black outline */
}

.message.hailey {
  left: 31%;  /* Adjusts Hailey's bubble position */
  top: 45%;
  background-color: rgb(111, 111, 114);
  border-radius: 1000px;  /* More rounded */
  padding: 20px;        /* Larger padding */
  font-size: 16px;      /* Larger text */
  border: 2px solid black;  /* Black outline */
}



.bubble {
  padding: 10px;
  border-radius: 15px;
  display: inline-block;
  width: 250px;
  word-wrap: break-word;
  font-size: 16px;
  position: relative;
  text-align: center;  /* Center the text horizontally */
  color: white;  /* White text for all bubbles */
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.chat-input {
  display: flex;
  position: absolute; /* Use absolute positioning */
  left: 44%;  /* Adjust the horizontal position */
  top: 78%;   /* Adjust the vertical position */
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.chat-input button {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'San Francisco', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.footer {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 20px;
  background-color: #f1f1f1;
  color: #333;
}

.footer_disclaimer {
  margin-top: 10px;
}

.navigation_ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.navigation_ul li {
  margin: 0 15px;
}

.navigation_ul img {
  width: 30px;
  height: 30px;
}

.custom-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Slight transparency */
  padding: 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  border-top: 2px solid #333; /* Optional border */
}

.footer-icons {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-icons li {
  display: inline-block;
}

.footer-icons img {
  width: 40px;
  height: 40px;
  border-radius: 100%; /* Makes the icons circular */
  background-color: #fff;
  padding: 1px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.footer-icons a:hover img {
  background-color: #f0f0f0;
}


